import { Routes, Route, Navigate } from 'react-router-dom';
import { PATHS } from './types';
import { AppLayout, PrivateRoutes, CancelOrder, NotFound, OrderDetail, OrderList } from './pages';

function App() {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route element={<PrivateRoutes />}>
          <Route path={PATHS.INDEX} element={<Navigate to={PATHS.ORDERS} />} />
          <Route path={PATHS.ORDERS}>
            <Route index element={<OrderList />} />
            <Route path={PATHS.CANCEL_ORDER} element={<CancelOrder />} />
            <Route path={PATHS.ORDER_DETAIL} element={<OrderDetail />}>
              <Route path={PATHS.SHIPPING_ADDRESS} element={<OrderDetail />} />
            </Route>
          </Route>
          <Route path={PATHS.NOT_FOUND} element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
