import { useTranslation } from 'react-i18next';
import { formatPaymentType } from '../../utils';
import { Typography } from '@photosi/web-design-system';

type Props = {
  paymentType?: string;
  canceled?: boolean;
};

const PaymentDetails: React.FC<Props> = ({ paymentType, canceled }) => {
  const { t } = useTranslation('orderDetail');

  return (
    <div>
      <Typography variant="headingXL" className="pds-hidden sm:pds-block">
        {t('paymentInfo')}
      </Typography>
      <Typography variant="headingL" className="sm:pds-hidden pds-block">
        {t('paymentInfo')}
      </Typography>
      <div className="pds-text-sm md:pds-text-base md:pds-max-w-80 pds-mt-4">
        {canceled ? t('payment:canceled') : formatPaymentType(paymentType)}
      </div>
    </div>
  );
};

export default PaymentDetails;
