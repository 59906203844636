import { useQuery } from 'react-query';
import { API_KEY, API_ORDER_LIST } from '../../settings';
import { useUser } from '../../contexts/UserContext';
import IOrder from '../../types/Order';
import OrderStateEnum from '../../types/OrderState';

export function useOrderInfo(orderId: string) {
  const { authToken, lang } = useUser();

  const { data, isSuccess, isLoading, isError, refetch } = useQuery(
    `orderInfo_${orderId}`,
    async (): Promise<IOrder> => {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Accept-Language': lang,
          Authorization: `Bearer ${authToken}`,
          'x-api-key': `${API_KEY}`,
        },
      };
      const response = await fetch(`${API_ORDER_LIST}/${orderId}?includeDeliveryTimes=true`, { ...options });
      return response.json();
    },
  );

  return {
    data,
    isOrderCanceled: data?.orderState === OrderStateEnum.Cancelled,
    isSuccess,
    isLoading,
    isError,
    refetch,
  };
}
