import { Typography } from '@photosi/web-design-system';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { useOrderInfo } from '../../hooks/useOrderInfo';
import BackIcon from '../../assets/back-arrow.svg';

import { formatDate } from '../../utils';
import { Loader } from '../../components/Loader';
import { Error } from '../../components/Error';
import { DeliveryInfo } from '../../components/DeliveryInfo';
import { SupportUrl } from '../../components/SupportUrl';
import { ShippingHeader } from '../../components/ShippingHeader';
import { ShippingRows } from '../../components/ShippingRows';
import { ShippingDetails } from '../../components/ShippingDetails';
import { PaymentDetails } from '../../components/PaymentDetails';
import { PriceDetails } from '../../components/PriceDetails';

const OrderDetail: React.FC = () => {
  const { orderId } = useParams() as {
    orderId: string;
  };

  const { t } = useTranslation(['orderDetail', 'orderList']);

  const { data, isOrderCanceled, isLoading, isError } = useOrderInfo(orderId);
  const { userId } = useUser();

  const { state } = useLocation();

  const fromPage: string = state?.currentPage || '1';

  // workaround for missing API authorization management
  const correctUser = `${data?.userId}` === userId;
  const orderNumber = data?.number?.toString() as string;

  if (isLoading) return <Loader />;
  if (isError || !data || !correctUser) return <Error />;

  return (
    <div className="pds-flex-col pds-w-full pds-space-y-4">
      <p className="pds-text-primary">
        <Link to={{ pathname: '/orders', search: `page=${fromPage}` }} className="pds-flex pds-items-center">
          <img src={BackIcon} alt={t('goBack')} />
          <span className="pds-text-lg pds-pl-2 pds-align-top pds-text-water-500">
            {t('goBack', { ns: 'orderDetail' })}
          </span>
        </Link>
      </p>
      <div data-testid="order-detail">
        <Typography variant="heading2XL" className="pds-font-medium pds-mb-4 pds-text-center">
          {t('orderDetail', { ns: 'orderList' })}
        </Typography>
        <div className="pds-w-full pds-flex pds-flex-wrap pds-content-center pds-space-y-1 md:pds-hidden pds-px-2 pds-mb-5 pds-gap-2 sm:pds-gap-0">
          <div className="xs:pds-w-full pds-w-full xs:pds-flex sm:pds-w-1/2 sm:pds-mb-4 pds-flex sm:pds-flex-col pds-justify-between sm:pds-justify-normal pds-items-center sm:pds-items-start">
            <Typography className="pds-font-semibold xs:pds-flex-auto">{t('orderNumber')}:</Typography>
            <Typography className="xs:pds-flex-auto">{orderNumber}</Typography>
          </div>
          <div className="xs:pds-w-full pds-w-full xs:pds-flex sm:pds-w-1/2 sm:pds-mb-4 pds-flex sm:pds-flex-col pds-justify-between sm:pds-justify-normal pds-items-center sm:pds-items-start">
            <Typography className="pds-font-semibold xs:pds-flex-auto">{t('createdOn')}:</Typography>
            <Typography className="xs:pds-flex-auto">{formatDate(data.creationDate)}</Typography>
          </div>
          <div className="xs:pds-w-full pds-w-full xs:pds-flex sm:pds-w-1/2 sm:pds-mb-4 pds-flex sm:pds-flex-col pds-justify-between sm:pds-justify-normal pds-items-center sm:pds-items-start">
            <DeliveryInfo orderState={data.orderState} expectedDeliveryDate={data.expectedDeliveryDate} />
          </div>

          <SupportUrl orderNumber={orderNumber} />
        </div>
        <div className="pds-mb-4 pds-p-6 pds-border pds-border-gray-200 pds-border-b pds-rounded-3xl pds-w-full pds-shadow-lg">
          <ShippingHeader {...data} orderNumber={orderNumber} />
          <ShippingRows />
        </div>
        <div className="pds-mb-4 pds-p-6 pds-border pds-border-gray-200 pds-border-b pds-rounded-3xl pds-w-full pds-shadow-lg">
          <div className="pds-grid pds-grid-cols-1 md:pds-grid-cols-3 md:pds-gap-x-20 pds-gap-x-5 pds-gap-y-5">
            <ShippingDetails order={data} />
            <PaymentDetails paymentType={data?.paymentType} canceled={isOrderCanceled} />
            <PriceDetails {...data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
