import * as braze from '@braze/web-sdk';

const featureFlagList = {
  enableOrderCancellation: 'enable_order_cancellation',
  betaUserOrderCancellation: 'beta_user_order_cancellation',
};

export type FeatureFlag = keyof typeof featureFlagList;

/**
 * Retrieves the enabled status of a specific feature flag.
 *
 * @param feature - The feature flag to check.
 * @returns The enabled status of the feature flag. Returns `undefined` if the feature flag is not found.
 */
export function getFeatureFlagEnabled(feature: FeatureFlag): boolean | undefined {
  const selectedFeatureFlag = featureFlagList[feature];
  const featureFlag = braze.getFeatureFlag(selectedFeatureFlag) as braze.FeatureFlag;
  braze.logFeatureFlagImpression(selectedFeatureFlag);
  const isFeatureEnabled = !!featureFlag?.enabled;
  return isFeatureEnabled;
}
