import * as amplitude from '@amplitude/analytics-browser';
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_API_KEY } from '../settings';
import { isMobile } from './isMobile';

type AmplitudeInit = {
  userId: string;
  userType: 'retailer' | 'consumer';
};

export type OrderDeleteViewSource = 'thankyoupage' | 'my_order';

type TrackClickData = {
  type: 'track' | 'detail';
  level: 'order' | 'product';
};

export class Amplitude {
  private static instance: Amplitude;

  private constructor({ userId, userType }: AmplitudeInit) {
    amplitude.init(AMPLITUDE_API_KEY, userId, {
      defaultTracking: false,
      trackingOptions: { ipAddress: false },
    });
    amplitude.setUserId(userId);
    const platform = isMobile() ? 'Mobile' : 'Desktop';

    const identifyObj = new amplitude.Identify();
    identifyObj.set('platform_type', platform);
    identifyObj.set('user_type', userType);
    amplitude.identify(identifyObj);
  }

  public static init(userInfo: AmplitudeInit): Amplitude {
    if (!Amplitude.instance) {
      Amplitude.instance = new Amplitude(userInfo);
    }
    return Amplitude.instance;
  }

  protected static async logEvent(eventName: string, eventProperties?: any): Promise<void> {
    await track(eventName, eventProperties).promise;
  }

  public static orderDeleteLinkClick = async () => {
    await Amplitude.logEvent('my_order_delete_click');
  };

  public static orderDeleteView = async (source: OrderDeleteViewSource) => {
    await Amplitude.logEvent('my_order_delete_view', { source });
  };

  public static orderDeleteConfirmClick = async (reason: string) => {
    await Amplitude.logEvent('my_order_delete_confirm_click', { reason });
  };

  public static orderDeleteSuccess = async () => {
    await Amplitude.logEvent('my_order_delete_success');
  };

  public static orderDeleteError = async (error: string) => {
    await Amplitude.logEvent('my_order_delete_error', { error });
  };

  public static orderDetailClick = async () => {
    await Amplitude.logEvent('my_order_detail_click');
  };

  public static orderTrackClick = async (data: TrackClickData) => {
    await Amplitude.logEvent('my_order_track_click', data);
  };

  public static orderHelpClick = async () => {
    await Amplitude.logEvent('my_order_help_click');
  };

  public static setAmplitudeFeatureFlag(featureFlag: string, featureFlagValue: string): void {
    const identify: amplitude.Identify = new amplitude.Identify();
    identify.set(featureFlag, featureFlagValue);
    amplitude.identify(identify);
  }
}
