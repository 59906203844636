import { useTranslation } from 'react-i18next';
import IOrder from '../../types/Order';
import { formatDate } from '../../utils';
import { DeliveryInfo } from '../DeliveryInfo';
import { Typography } from '@photosi/web-design-system';
import { SupportUrl } from '../SupportUrl';

type Props = { orderNumber: string } & Pick<IOrder, 'orderState' | 'creationDate' | 'expectedDeliveryDate' | 'guid'>;

const ShippingHeader: React.FC<Props> = ({ orderState, orderNumber, creationDate, expectedDeliveryDate }) => {
  const { t } = useTranslation('orderDetail');

  return (
    <div className="pds-border-b pds-border-400 md:pds-p-0 md:pds-pb-6 pds-mb-6 md:pds-flex pds-flex-col md:pds-flex-row pds-justify-between pds-header pds-hidden">
      <div className="pds-flex pds-flex-col pds-w-full md:pds-w-3/4 sm:pds-w-2/3 pds-mb-5 md:pds-mb-0 pds-space-y-4">
        <Typography variant="headingXL">
          {t('order')} {orderNumber}
        </Typography>
        <div className="pds-flex pds-flex-col pds-space-y-1">
          <span>
            {t('createdOn')}: {formatDate(creationDate)}
          </span>
          <DeliveryInfo orderState={orderState} expectedDeliveryDate={expectedDeliveryDate} />
        </div>
      </div>
      <div className="md:pds-w-1/4 sm:pds-w-full xs:pds-w-full pds-hidden pds-self-center sm:pds-flex pds-flex-col pds-space-y-4">
        <SupportUrl orderNumber={orderNumber} />
      </div>
    </div>
  );
};

export default ShippingHeader;
